<template>
  <div class="ShareModule">
    <div class="ShareModuleContent">
      <div class="ShreUser">
        <UserAvatar class="ShreUserAvatar" :user="creator" size="100px" />
        <div class="UserShareName">@{{ creator.user }}</div>
      </div>
      <div class="ShareModuleButtons">
        <VueSlickCarousel v-bind="settings" class="storiesCarrousel" ref="slider">
          <div class="ShareButtonWrap" v-for="(button, index) in buttons" :key="index">
            <CircleButton class="ShareButton" :icon="button.icon" :label="buttonLabel(button)" size="56px" @click="shareOn(button.icon)" />
          </div>
        </VueSlickCarousel>
        <Spacer num="2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["creator", "modal", "post"],
  data: function() {
    return {
      //baseUrl: `https://share.myfans1a.com/${this.creator.user}${this.post ? `?post=${this.post._id}` : ``}`,
      text: `@${this.creator.user} on MyFans1A`,
      settings: {
        arrows: false,
        dots: true,
        mobileFirst: true,
        infinite: false,
        initialSlide: 0,
        slidesToShow: 4.5,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 3,
              dots: true,
            },
          },
        ],
      },
      buttons: [
        { icon: "copy", label: () => this.$locale["copy_link"], size: "56px" },
        { icon: "whatsapp", label: "Whatsapp", size: "56px" },
        { icon: "instagram", label: "Instagram", size: "56px" },
        { icon: "facebook", label: "Facebook", size: "56px" },
        { icon: "twitter", label: "Twitter", size: "56px" },
        { icon: "telegram", label: "Telegram", size: "56px" },
        { icon: "email", label: "Email", size: "56px" },
        { icon: "more", label: () => this.$locale["more"], size: "56px" },
      ],
    };
  },
  computed: {
    shareMessage: function() {
      return `${this.text} ${this.baseUrl}`;
    },
    baseUrl: function() {
      let url = `https://share.myfans1a.com/${this.creator.user}${this.post ? `?post=${this.post._id}` : ``}`;

      if (process.env.VUE_APP_MODE !== "prod") {
        url = `${process.env.VUE_APP_API_HOST}creator/shared/${this.creator.user}${this.post ? `?post=${this.post._id}` : ``}`;
      }

      return url;
    },
  },
  methods: {
    buttonLabel: function(button) {
      return typeof button.label === "function" ? button.label() : button.label;
    },
    shareOn: function(network, force) {
      this.shares = {
        instagram: `https://www.instagram.com/web/share/?url=${this.url_encode(this.baseUrl)}&text=${this.url_encode(this.shareMessage)}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${this.url_encode(this.baseUrl)}&quote=${this.url_encode(this.shareMessage)}`,
        twitter: `https://twitter.com/intent/tweet?text=${this.url_encode(this.shareMessage)}`,
        whatsapp: `https://api.whatsapp.com/send?text=${this.url_encode(this.shareMessage)}`,
        telegram: `https://telegram.me/share/url?url=${this.url_encode(this.baseUrl)}&text=${this.url_encode(this.shareMessage)}`,
        email: `mailto:?subject=${this.url_encode(this.text)}&body=${this.url_encode(this.shareMessage)}`,
      };

      this.unsupport = {
        instagram: true,
      };

      this.modal.close();

      if (network === "more") {
        return this.tryNativeShare(network, true);
      }

      if (this.unsupport[network] && !force) {
        return this.tryNativeShare(network);
      }

      if (network === "copy") {
        this.copyToClipboard(this.baseUrl);
        return;
      }

      window.open(this.shares[network], "_blank");
    },
    canNativeShare: function() {
      return navigator.share !== undefined;
    },
    tryNativeShare: function(network, stop) {
      if (this.canNativeShare()) {
        return navigator.share({
          title: this.text,
          text: this.shareMessage,
          url: this.baseUrl,
        });
      }

      if (stop) {
        return;
      }

      return this.shareOn(network, true);
    },
    copyToClipboard: function() {
      this.$global.toClipboard(this.baseUrl, () => {
        this.ialert.go({
          type: "notification",
          title: this.$locale["object"]["post-context"]["copy-link"],
          message: this.$locale["object"]["post-context"]["link-copied"],
        });
      });
    },
  },
};
</script>

<style lang="scss">
.ShareView {
  position: relative;
  .ShreUser {
    padding: $mpadding * 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .UserShareName {
    padding: $mpadding 0 0 0;
  }
  .slick-track {
    display: flex;
  }
  .ShareModuleContent {
    position: relative;
    overflow: hidden;
  }
  .CircleButtonLabel {
    font-size: 90%;
    width: calc(calc(var(--card-width) - #{$mpadding * 2}) / 3.5);
    text-align: center;
  }
  .slick-track .slick-slide {
    width: calc(var(--card-width) / 3.5);
    max-width: calc(var(--card-width) / 3.5);
  }
  .ShareModuleButtons {
    padding: $mpadding 0;
  }
  .slick-dots {
    bottom: -$mpadding * 2;
  }
  .ShareButtonWrap {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
